import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/pagetitle"

const CriminalDefense = () => (
  <Layout>
    <PageTitle title="Criminal Defense Attorney" page="PageTitleCriminal" />
    <Seo title="Criminal Defense Lawyer in Annapolis MD" description="The Law Office of Sean E. Becker, LLC handles Criminal Defense, including everything from infractions and traffic violations to misdemeanors and major felonies." />
    <div className="content-page-wrapper">
        <div className="page-intro">
            <p>
            The Law Office of Sean E. Becker, LLC handles Criminal Defense, including everything from infractions and traffic violations to misdemeanors and major felonies. It also includes post-conviction matters, such as sentence modifications, appeals and expungements. All Criminal Charges can have serious implications and should be taken seriously. Sean works directly with his clients to investigate all areas relating to the charges. Every case is unique and Sean works with individuals to protect their rights and prepare them for the various possibilities to come. Sean has handled various criminal charges across the State of Maryland.
            </p>            
        </div> 
    </div>        
  </Layout>
)

export default CriminalDefense
